import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPickupHoursDisabled
    ? _c(
        VLayout,
        { attrs: { "justify-center": "", "align-center": "", column: "" } },
        [
          _c(
            VFlex,
            { staticClass: "mt-4", attrs: { xs12: "" } },
            [
              _c(VImg, {
                attrs: {
                  src: require("@/assets/undraw-pickup.svg"),
                  height: 164,
                  width: 215,
                  clss: "svg-image",
                  contain: ""
                }
              })
            ],
            1
          ),
          _c(VFlex, { attrs: { xs12: "" } }, [
            _c("div", { staticClass: "Body-1-Black-High-Emphasis-Center" }, [
              _vm._v(_vm._s(_vm.pickupDisabledMessage))
            ])
          ])
        ],
        1
      )
    : _c(
        VLayout,
        { attrs: { wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _vm.loading
                ? _c(VLayout, { attrs: { "justify-center": "" } }, [
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c(
                          VContainer,
                          { attrs: { "grid-list-xl": "" } },
                          [
                            _c(
                              VLayout,
                              {
                                staticStyle: { height: "45vh" },
                                attrs: {
                                  column: "",
                                  "justify-center": "",
                                  "fill-height": "",
                                  "align-center": ""
                                }
                              },
                              [
                                _c(VProgressCircular, {
                                  attrs: {
                                    size: 90,
                                    width: 9,
                                    color: "primary",
                                    indeterminate: ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _c(
                    VForm,
                    {
                      ref: "form",
                      attrs: { xs12: "", "lazy-validation": "" },
                      model: {
                        value: _vm.isFormValid,
                        callback: function($$v) {
                          _vm.isFormValid = $$v
                        },
                        expression: "isFormValid"
                      }
                    },
                    [
                      _c(
                        VContainer,
                        { attrs: { "text-xs-center": "" } },
                        [
                          _c(
                            VLayout,
                            {
                              attrs: { row: "", wrap: "", "align-baseline": "" }
                            },
                            [
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _vm._l(_vm.businessHours, function(day) {
                                    return [
                                      _c("hours", {
                                        key: day.id,
                                        attrs: { day: day },
                                        on: {
                                          "update:day": function($event) {
                                            day = $event
                                          }
                                        }
                                      })
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            VFlex,
            {
              staticStyle: { position: "sticky", bottom: "0" },
              attrs: { xs12: "" }
            },
            [
              _vm.isFormDirty
                ? _c(
                    VBtn,
                    {
                      staticClass: "mt-4",
                      staticStyle: { float: "right" },
                      attrs: {
                        color: "primary rounded m-2",
                        loading: _vm.saving
                      },
                      on: { click: _vm.saveCalendar }
                    },
                    [
                      _c(VIcon, { staticClass: "mr-3" }, [
                        _vm._v("mdi-content-save")
                      ]),
                      _vm._v("Save\n    ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
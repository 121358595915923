<template>
  <v-layout v-if="isPickupHoursDisabled" justify-center align-center column>
    <v-flex xs12 class="mt-4">
      <v-img
        :src="require('@/assets/undraw-pickup.svg')"
        :height="164"
        :width="215"
        clss="svg-image"
        contain
      ></v-img>
    </v-flex>
    <v-flex xs12>
      <div class="Body-1-Black-High-Emphasis-Center">{{ pickupDisabledMessage }}</div>
    </v-flex>
  </v-layout>
  <v-layout v-else wrap>
    <v-flex xs12>
      <v-layout v-if="loading" justify-center>
        <div class="mt-5">
          <v-container grid-list-xl>
            <v-layout column justify-center fill-height align-center style="height: 45vh;">
              <v-progress-circular
                :size="90"
                :width="9"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-layout>
          </v-container>
        </div>
      </v-layout>
      <v-form v-else xs12 ref="form" lazy-validation v-model="isFormValid">
        <v-container text-xs-center>
          <v-layout row wrap align-baseline>
            <v-flex xs12>
              <template v-for="day in businessHours">
                <hours :key="day.id" :day.sync="day"></hours>
              </template>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-flex>
    <v-flex xs12 style="position: sticky; bottom: 0;">
      <v-btn
        class="mt-4"
        color="primary rounded m-2"
        v-if="isFormDirty"
        :loading="saving"
        style="float: right;"
        @click="saveCalendar"
      >
        <v-icon class="mr-3">mdi-content-save</v-icon>Save
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import DateTime from 'luxon/src/datetime.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CalendarTypes } from '@/constants';
import hours from '../../hours';

export default {
  name: 'OperationHours',
  components: {
    hours,
  },
  data: () => ({
    businessHours: [],
    businessHoursToCompare: [],
    isFormValid: false,
    loading: true,
    saving: false,
  }),
  async beforeRouteLeave(to, from, next) {
    if (this.isFormDirty) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    isActiveBrandCalendarFetched: {
      async handler(value) {
        this.loading = true;
        if (!value) return;
        this.initBusinessHours();
        this.loading = false;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('sites', ['active_brand', 'active_site']),
    ...mapState('calendar', ['isActiveBrandCalendarFetched']),
    ...mapGetters('calendar', {
      getEvents: 'getEvents',
    }),
    isFormDirty() {
      return !isEqual(this.businessHoursToCompare, this.businessHours);
    },
    today() {
      return DateTime.local().toISODate();
    },
    isPickupHoursDisabled() {
      const pickupSupported = Boolean(this.active_brand?.is?.pickup_supported);
      const isCentricOSStation = Boolean(this.active_brand?.is?.cafe360);
      return !pickupSupported || isCentricOSStation;
    },
    pickupDisabledMessage() {
      const isCentricOSStation = Boolean(this.active_brand?.is?.cafe360);
      return isCentricOSStation
        ? 'Service hours automatically generated for CentricOS enabled station.'
        : 'Pickup is not enabled for this station.';
    },
  },
  methods: {
    initBusinessHours() {
      const businessHoursEvents = this.getEvents(CalendarTypes.business);
      this.businessHours = this.convertEventsToHours(businessHoursEvents);

      for (let index = 0; index < 7; index += 1) {
        const day = this.businessHours.find((hour) => hour.id === index);

        if (!day) {
          this.businessHours.push({
            id: index,
            dayLabel: this.dayOfWeekAsInteger(index),
            openTime: 'Closed',
            closeTime: 'Closed',
            is24Hours: false,
            isClosedAllDay: true,
            duration: 0,
            isCloseNextDay: false,
          });
        } else {
          day.duration = this.getDuration(day);
        }
      }
      this.businessHours.sort((a, b) => (a.id < b.id ? -1 : 1));
      this.businessHoursToCompare = cloneDeep(this.businessHours);
    },
    convertEventsToHours(eventHours) {
      return eventHours.map((event) => {
        const { start, end, isAllDay, isCloseAllDAy } = this.getStartAndEndTimes(event.schedule);

        return {
          id: event.schedule.dayOfWeek[0],
          dayLabel: this.dayOfWeekAsInteger(event.schedule.dayOfWeek[0]),
          name: event.data.title,
          openTime: start,
          closeTime: end,
          is24Hours: isAllDay,
          isClosedAllDay: isCloseAllDAy,
          duration: 0,
          isCloseNextDay: false,
        };
      });
    },
    getDuration(times) {
      if (!times.openTime || !times.closeTime || times.isClosedAllDay) return 0;
      const today = DateTime.local();
      const startTimes = times.openTime.split(':');
      const closeTime = times.closeTime.split(':');

      let startDateTime = DateTime.local();
      let closeDateTime = DateTime.local();

      if (startTimes.length === 1) {
        startDateTime = today.set({ hour: startTimes[0], minutes: '0' });
      } else {
        startDateTime = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      if (closeTime.length === 1) {
        closeDateTime = today.set({ hour: closeTime[0], minutes: '0' });
      } else {
        closeDateTime = today.set({ hour: closeTime[0], minute: closeTime[1] });
      }
      let duration = closeDateTime.diff(startDateTime, 'hours');
      let hours_diff = duration.values.hours;

      if (hours_diff <= 0) {
        times.isCloseNextDay = true;
        const nextDayCloseDateTime = closeDateTime.plus({ days: 1 });
        duration = nextDayCloseDateTime.diff(startDateTime, 'hours');
        hours_diff = duration.values.hours;
      } else {
        times.isCloseNextDay = false;
      }

      return hours_diff;
    },
    convertHoursToEvents(businessHours) {
      return businessHours
        .filter((businessHour) => !businessHour.isClosedAllDay)
        .map((businessHour) => {
          const schedule = { dayOfWeek: [businessHour.id] };

          if (businessHour.is24Hours) {
            schedule.duration = 1;
          } else {
            schedule.duration = Math.abs(businessHour.duration);
            schedule.times = [businessHour.openTime];
          }

          return {
            schedule,
            data: {
              title: 'Pickup Hours',
              color: '#ff8c84',
              calendar: CalendarTypes.business,
            },
          };
        });
    },
    async saveCalendar() {
      const v = this.$refs.form.validate();
      if (!this.isFormValid || !v) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      const businessHoursEvents = this.convertHoursToEvents(this.businessHours);

      try {
        this.saving = true;
        await this.saveEvents({ events: businessHoursEvents, type: CalendarTypes.business });
        this.$store.dispatch('sites/fetchSite', {
          id: this.active_site.id,
          multigroupId: this.$route.params.app,
          nocache: 1,
        });
      } catch (error) {
        this.$toast.error('Error saving pickup hours');
        return;
      }

      this.saving = false;
      this.$toast('Pickup hours saved');
      this.businessHoursToCompare = cloneDeep(this.businessHours);
    },
    dayOfWeekAsInteger(day) {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
    },
    ...mapActions('calendar', { saveEvents: 'saveEvents' }),
    getStartAndEndTimes(schedule) {
      if (!schedule && !schedule.times && !schedule.duration) return {};
      if (!schedule.times) {
        return {
          start: '00:00',
          end: '00:00',
          isAllDay: true,
          isCloseAllDAy: false,
        };
      }
      const today = DateTime.local();
      const startTime = schedule.times[0];
      const startTimes = startTime.split(':');

      let startDate;

      if (startTimes.length === 1) {
        startDate = today.set({ hour: startTimes[0], minute: '00' });
      } else {
        startDate = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      const endDate = startDate.plus({ hours: schedule.duration });

      return {
        start: startDate.toFormat('HH:mm'),
        end: endDate.toFormat('HH:mm'),
        isAllDay: false,
        isCloseAllDAy: false,
      };
    },
  },
};
</script>

<style></style>
